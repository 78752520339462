export default [
  'en_GB',
  'de_DE',
  'es_ES',
  'it_IT',
  'fr_FR',
  'pl_PL',
  'en_NL',
  'de_BE',
  'en_BE',
  'en_PT',
  'es_PT',
  'de_AT',
  'en_SE',
  'en_HU',
  'en_CZ',
  'en_GR',
  'en_RO',
  'ru_UA',
  'en_DK',
  'en_FI',
  'en_BG',
  'en_IE',
  'en_LV',
  'en_NO',
  'en_SK',
  'en_EE',
  'en_LT',
  'en_SI',
  'de_LU',
  'fr_LU',
  'en_LU',
  'en_CY',
  'en_HR',
  'tr_TR'
];
