/* global logger */

/**
 * @namespace actions
 */

import { fromJS } from 'immutable';
import { allLabels, supportedLocales } from '../config/client';
import staticLabels from '../labels';
import { labelsApi } from '../config/server';
import request from '../http/request';

export const fetchLabels = ({ locale, modules = [] }) => async dispatch => {
    /**
     * @method fetchLabels
     * @description Async function to fetch and return label data. Can return either all or only specifics
     *
     * @memberof actions
     *
     *
     * @param {object} object
     * @param {string} object.locale - Locale to fetch labels for
     * @param {array} object.modules - Standard module array used in rendering of Meerkat-II components
     *
     * @returns {promise}
     *
     * @todo DW [2020/02/18]: slack webhook when labels are notFound
     *
     * @example
     * return this.props.fetchLabels({ labels, modules });
     */

    const labelUri = labelsApi + `/${supportedLocales[locale]}/api/meerkat/cms/cache/labels`;

    /**
     * push in the static modules such as navigation for label fetching
     */

    modules = fromJS(modules).toJS();

    modules.push(
        {
            name: 'Navigation',
            data: {},
        },

        {
            name: 'Footer',
            data: {},
        }
    );

    /**
     * map the modules array and create a list of unique module names
     */

    const moduleNames = [
        ...new Set(
            modules.map(module => {
                const { name } = module;
                return name;
            })
        ),
    ];

    /**
     * Call the static functions to return the required labels
     */
    logger.info('src.actions.fetchLabels.js: Requesting labels via action for %s for %s locale', labelUri, locale);

    logger.info('src.actions.fetchLabels.js: Fetching labels for %s', JSON.stringify(moduleNames));

    const labels = [
        ...new Set(
            modules
                .map((module = {}) => {
                    const { name, data = {} } = module;

                    return staticLabels[name] ? staticLabels[name]({ data }) : null;
                })
                .flat()
                .flat()
                .filter(Boolean)
        ),
    ];

    logger.info('src.actions.fetchLabels.js: Fetching the following labels - %s', JSON.stringify(labels));

    let payload = {};

    if (allLabels) {
        payload = { locale, all: allLabels };
    } else {
        payload = { locale, labels };
    }

    return request
        .get(labelUri)
        .then(response => {
            const { body = {} } = response;

            logger.info('src.actions.fetchLabels.js: Success Fetching labels');

            dispatch({
                type: 'LABELS',
                payload: {
                    labels: body,
                },
            });

            dispatch({
                type: 'LOADING',
                payload: {
                    labels: false,
                },
            });

            return body;
        })
        .catch(error => {
            dispatch({
                type: 'LOADING',
                payload: {
                    labels: false,
                },
            });

            logger.error(
                'src.routes.api.fetchLabels.js: error fetching from remote API for %s for %s: %s',
                labelUri,
                locale,
                error
            );
        });
};
