/* TO BE DECOMMISSIONED */

/* global jwplayer, logger */

/**
 * @namespace helpers
 */

import { isClient } from '../';

/**
 * @method pauseAllVideos
 * @description Utility script to pause all JwVideo's on the page.
 *
 * @memberof helpers
 *
 *
 * @todo DW [2019/12/20]: Write tests, factoring in requirement to load remote script
 *
 * @example
 * pauseAllVideos();
 */

export default () => {
    if (isClient()) {
        const players = document.getElementsByClassName('jwplayer');

        [].slice.call(players).map((player = {}) => {
            try {
                const { id } = player;
                const playerElement = jwplayer(id);

                if (playerElement.getState() === 'playing') {
                    playerElement.pause();
                }
            } catch (err) {
                logger.warn(err);
            }

            return true;
        });
    }

    return;
};
