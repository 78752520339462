/**
 * @module labels/index
 *
 * @description Defines static methods for returning labels that each module requires. Label values are computed where necessary.
 *
 * @todo DW [2020/02/18]: write tests
 *
 *
 *
 */

export default {
    ContentCard: () => {
        return ['readMore'];
    },
    Navigation: () => {
        return [
            'openNavigation',
            'search',
            'yourAccount',
            'expandedNavViewAll',
            'welcomeBackPersonalised',
            'account',
            'logout',
        ];
    },
    StandardText: ({ data = {} }) => {
        const { titleLabel, contentLabel } = data;

        return [titleLabel, contentLabel];
    },

    /* JWPLAYER TO BE DECOMMISSIONED */

    JwVideo: () => {
        return [
            'vimeo_en',
            'vimeo_fr',
            'vimeo_de',
            'vimeo_es',
            'vimeo_it',
            'vimeo_pl',
            'vimeo_tr',
            'vimeo_ko',
            'vimeo_ru',
            'vimeo_es-419',
            'vimeo_fr-CA',
            'vimeo_pt-BR',
        ];
    },
    AssetList: () => {
        return ['emptyResults', 'search', 'previous', 'next', 'goToPage'];
    },
    HeroBanner: ({ data = {} }) => {
        const { isHomeBanner, buttons = [] } = data;

        if (!isHomeBanner) {
            return buttons.map((button = {}) => {
                const { title, useLabel } = button;

                if (useLabel) {
                    return [`productIntroButton${title}`];
                } else {
                    return null;
                }
            });
        }

        return;
    },
    Footer: () => {
        return ['footerStandard', 'footerUser', 'footerContact'];
    },
    StickyNav: ({ data = {} }) => {
        const { nav = [] } = data;

        const navLabels =
            nav && nav.length > 0
                ? nav.map((navItem = {}) => {
                      const { name } = navItem;

                      return name;
                  })
                : [];

        return ['export', 'whereToBuy', 'navigationOpen', ...navLabels];
    },
    ProductImageGallery: () => {
        return ['currentlyOnScreen', 'slide', 'imageGalleryTitle'];
    },
    ProductSpecificationsBlock: () => {
        return ['productSpecsTitle'];
    },
    ProductIntroBanner: ({ data = {} }) => {
        const { buttons = [], price: prices = [] } = data;

        const buttonLabels =
            buttons && buttons.length > 0
                ? buttons.map((button = {}) => {
                      const { title } = button;

                      return `productIntroButton${title}`;
                  })
                : [];

        const priceLabels =
            prices && prices.length > 0
                ? prices.map((price = {}) => {
                      const { currency } = price;

                      return `listPriceFor${currency}`;
                  })
                : [];

        return ['isDiscontinued', 'priceAvailableOnRequest', ...buttonLabels, ...priceLabels];
    },
    ProductCategory: ({ data = {} }) => {
        const { genericName, categories = [] } = data;

        const categoryLabels =
            categories && categories.length > 0
                ? categories.map((category = {}) => {
                      const { genericName } = category;

                      return [
                          `${genericName}_bullet1`,
                          `${genericName}_bullet2`,
                          `${genericName}_bullet3`,
                          `${genericName}_bullet4`,
                      ];
                  })
                : [];

        return [`${genericName}_description`, ...categoryLabels];
    },
    HomepageHero: () => {
        return ['currentlyOnScreen', 'slide'];
    },
    NewsLinkBlocks: () => {
        return ['currentlyOnScreen', 'slide', 'readMore'];
    },
    PSProductDetails: () => {
        return ['productName', 'serialNumber', 'warrantyEndDate'];
    },
    EnhancedRfi: () => {
        return [
            'archiving_description',
            'medical_description',
            'professionaldisplays_description',
            'projectors_description',
            'videosecurity_description',
            'amasystem_description',
            'broadcast_description',
            'broadcastaudio_description',
            'poweringcreativity_description',
            'procamcamcorders_description',
            'procamhandheld_description',
            'prodisplays_description',
            'unifiedcomms_description',
            'smartsystems_description',
            'fieldIsRequired',
            'enhancedRfi_fieldSerialNumberIsRequired',
            'emailIsRequired',
            'fieldSelectIsRequired',
            'enhancedRfi_ErrorFileType',
            'enhancedRfi_MaxFileSize',
            'enhancedRfi_MaxFileNumber',
            'enhancedRfi_SelectProduct_archiving',
            'enhancedRfi_SelectProduct_medical',
            'enhancedRfi_SelectProduct_professionaldisplays',
            'enhancedRfi_SelectProduct_projectors',
            'enhancedRfi_SelectProduct_videosecurity',
            'enhancedRfi_SelectProduct_amasystem',
            'enhancedRfi_SelectProduct_broadcast',
            'enhancedRfi_SelectProduct_broadcastaudio',
            'enhancedRfi_SelectProduct_poweringcreativity',
            'enhancedRfi_SelectProduct_procam',
            'enhancedRfi_SelectProduct_cinemaline',
            'enhancedRfi_SelectProduct_smartsystems',
            'enhancedRfi_SelectProduct_probravia',
            'enhancedRfi_SelectProduct_networkcamera',
            'enhancedRfi_Title',
            'enhancedRfi_IntroText',
            'enhancedRfi_SelectProductCategory',
            'enhancedRfi_ContactsTableCountry',
            'enhancedRfi_ContactsTablePhone',
            'communicationLanguageFormField',
            'enhancedRfi_ContactsInfo1',
            'enhancedRfi_ContactsInfo2',
            'enhancedRfi_ContactsViewAll',
            'enhancedRfi_ProductDetails',
            'enhancedRfi_ProductDetailsHint',
            'enhancedRfi_ProductDetailsClick',
            'enhancedRfi_ProductModelName',
            'serialNumber',
            'enhancedRfi_MessageDescription',
            'enhancedRfi_YourDetails',
            'rfiSubmissionField_firstName',
            'lastNameFormField',
            'rfiSubmissionField_emailAddress',
            'enhancedRfi_CountryPrefix',
            'enhancedRfi_SelectOption',
            'rfiSubmissionField_phoneNbr',
            'rfiFormFieldcompany',
            'enhancedRfi_ContactsTableCountry',
            'enhancedRfi_SelectOption',
            'rfiCountryAL',
            'rfiCountryAT',
            'rfiCountryBE',
            'rfiCountryBA',
            'rfiCountryBG',
            'rfiCountryHR',
            'rfiCountryCY',
            'rfiCountryCZ',
            'rfiCountryDK',
            'rfiCountryEE',
            'rfiCountryFI',
            'rfiCountryFR',
            'rfiCountryDE',
            'rfiCountryGR',
            'rfiCountryGL',
            'rfiCountryHU',
            'rfiCountryIS',
            'rfiCountryIE',
            'rfiCountryIL',
            'rfiCountryIT',
            'rfiCountryXK',
            'rfiCountryLV',
            'rfiCountryLT',
            'rfiCountryLU',
            'rfiCountryMK',
            'rfiCountryME',
            'rfiCountryNL',
            'rfiCountryNO',
            'rfiCountryPL',
            'rfiCountryPT',
            'rfiCountryRO',
            'rfiCountryRS',
            'rfiCountrySK',
            'rfiCountrySI',
            'rfiCountryES',
            'rfiCountrySE',
            'rfiCountryCH',
            'rfiCountryTR',
            'rfiCountryGB',
            'enhancedRfi_ReceivePromotions',
            'enhancedRfi_AttachFile',
            'enhancedRfi_AttachFileHint',
            'enhancedRfi_DeleteFile',
            'rfiFormFieldSubmit',
            'loading',
            'successTitle',
            'enhancedRfi_submitSuccess',
            'success',
            'errorTitle',
            'unknownRegistrationError',
        ];
    },
    FDLChangeCountry: () => {
        return ['fdlChangeCountry'];
    },
};
